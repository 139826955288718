import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import AngelaImgW from '../../images/angela.webp'
import AngelaImg from '../../images/angela.png'

import '../../styles/angela.scss'

export default function Angela({ children, location }) {
	return (
		<Layout currentPath={location}>
			<SEO title="Angela Patruno| Sobre Nosotros" />
			<div className="angela">
				<Link to="/nosotros/quienes-somos" className="back">
					&larr;Volver
				</Link>
				<div className="angela__title">
					<div className="angela__texture" />
					<h1>¿QUIÉNES SOMOS?</h1>
				</div>
				<div className="angela__content">
					<div className="angela__content__leftbar" />
					<div className="angela__inner-content">
						<h2 className="title--top">¿QUIÉN ES</h2>
						<h2 className="title--bottom">ÁNGELA MARÍA PATRUNO?</h2>
					</div>
					<div className="angela__content__rightbar" />
				</div>
				<div className="angela__subcontent">
					<div className="angela__photo">
						<picture>
							<source srcSet={AngelaImgW} type="image/webp" />
							<source srcSet={AngelaImg} type="image/png" />
							<img src={AngelaImg} alt="Angela Patruno Grupo Ínsula" />
						</picture>
					</div>
					<div className="angela__subcontent__right">
						<h4>Chief Sales Officer / Founder</h4>
						<div className="inner">
							<div className="leftbar" />
							<div>
								<p>
									Honestidad como principio fundamental. Todos los actos son el reflejo de nuestros
									ideales, por eso la responsabilidad con los demás y con la naturaleza es algo que
									siempre va en primer lugar.
								</p>
								<p>
									Las personas y el compromiso que tenemos con ellas siempre va a estar por encima de
									cualquier interés propio. Por eso la amabilidad, la calidez, la cercanía y el buen
									trato a las personas siempre estarán presentes en cada proyecto.
								</p>
							</div>

							<div className="rightbar" />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}
